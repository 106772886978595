<template>
  <div class="">
    <Notify></Notify>
    <v-row v-if="project">
      <v-col cols="12">
        <card-project :project="project"></card-project>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-grid"
        v-for="work in project.work_count_time"
        :key="work.id"
      >
        <v-card class="card-shadow mt-6 border-radius-xl pa-5">
          <div class="">
            <div class="d-flex justify-space-between mb-3">
              <h5 class="text-h6 text-body font-weight-bold mb-0">
                {{
                  $t("project.assign_employee") +
                  " " +
                  $t("project.for time") +
                  " " +
                  work.name
                }}
              </h5>

              <div>
                <span class="text-sm text-typo">
                  <!-- {{
                  $t("project.count_employee") + " : "
                  }} -->
                  <v-icon size="25"> mdi-account-group-outline </v-icon>
                </span>
                <span class="text-sm mx-2 text-typo">{{
                  work.count_employee
                }}</span>
              </div>
            </div>

            <div class="d-flex justify-space-between">
              <div class="text-xs text-body">
                {{
                  $t("project.start_time_permanence") + " :  " + work.start_time
                }}
              </div>
              <div class="text-xs text-body">
                {{ $t("project.end_time_permanence") + " :  " + work.end_time }}
              </div>
            </div>
            <div class="category-card mt-7" v-if="work.employee.length > 0">
              <h5 class="text-md text-body font-weight-bold mt-6 title">
                {{ $t("employee") }}
              </h5>
              <v-row>
                <v-col
                  cols="6"
                  md="4"
                  v-for="item in work.employee"
                  :key="item.id"
                >
                  <h5 class="text-xs mx-5 text-typo">{{ item.full_name }}</h5>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Notify from "../Components/Notify";
import projectService from "../../../services/project.service";
import CardProject from "./cardProject.vue";
export default {
  components: {
    Notify,
    CardProject,
  },
  data() {
    return {
      project: null,
      id: null,
    };
  },

  methods: {
    get_data() {
      return projectService.details(this.id).then(
        (response) => {
          this.project = response.data.project;
        },
        (error) => {
          console.log(error);
          this.$store.commit("form/SET_NOTIFY", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
          // this.$router.push({
          //   path: "/projects",
          //   params: { message: message },
          // });
        }
      );
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.get_data();
    document.title = this.$i18n.t("DetailsProject");
  },
};
</script>
<style scoped>
.category-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  padding-top: 26px;
  padding-bottom: 20px;
  position: relative;
}
.category-card .title {
  position: absolute;
  top: -37px;
  background: white;
  min-width: max-content;
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block;
}
</style>