<template>
  <v-card class="card-shadow mt-6 border-radius-xl pa-4">
    <div class="d-flex justify-space-between">
      <h5 class="text-h6 text-typo font-weight-bold mb-6">
        {{ $t("project.Project info") }}
      </h5>
      <div class="d-flex flex-column align-end">
        <div class="text-end">
          <v-icon>mdi-calendar</v-icon>
          {{ project.created_at | formatDate }}
        </div>
        <v-chip
          style="width: max-content"
          outlined
          label
          :color="color"
          class="mr-3 mt-2 overflow-visible"
          >{{ project.status && project.status.status_lang }}</v-chip
        >
      </div>
    </div>
    <v-row>
      <v-col cols="5">
        <div class="d-flex mb-3">
          <span class="text-xs font-weight-bold text-typo">{{
            $t("project.project_name") + " : "
          }}</span>
          <span class="mx-3 text-sm font-weight-normal text-body">{{
            project.project_name
          }}</span>
        </div>

        <div class="d-flex mb-3">
          <span class="text-xs font-weight-bold text-typo">{{
            $t("project.price_of_project") + " : "
          }}</span>
          <span class="mx-3 text-sm font-weight-normal text-body">{{
            project.price_of_project
          }}</span>
        </div>

        <div class="d-flex mb-3">
          <span class="text-xs font-weight-bold text-typo">{{
            $t("project.number_of_employee") + " : "
          }}</span>
          <span class="mx-3 text-sm font-weight-normal text-body">{{
            project.number_of_employee
          }}</span>
        </div>
      </v-col>
      <v-col cols="5">
        <div class="d-flex mb-3">
          <span class="text-xs font-weight-bold text-typo">{{
            $t("project.number_of_hour") + " : "
          }}</span>
          <span class="mx-3 text-sm font-weight-normal text-body">{{
            project.number_of_hour
          }}</span>
        </div>

        <div class="d-flex mb-3">
          <span class="text-xs font-weight-bold text-typo">{{
            $t("project.contractor") + " : "
          }}</span>
          <span class="mx-3 text-sm font-weight-normal text-body">{{
            project.contractor && project.contractor.name
          }}</span>
        </div>

        <div class="mb-3">
          <div class="text-xs mb-2 font-weight-bold text-typo">
            {{ $t("project.address_project") + " : " }}
          </div>
          <div class="text-sm font-weight-normal text-body">
            {{ project.address_project }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ["project"],
  computed: {
    color() {
      switch (this.project.status_id) {
        case 1:
          return "pending";
        case 2:
          return "dark";
        case 3:
          return "success";
        default:
          return "dafault";
      }
    },
  },
};
</script>